import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "extention"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    extention: file(relativePath: { eq: "extention/extention.PNG"}) { ...normalImg },
    pcbody: file(relativePath: { eq: "extention/pcbody.PNG"}) { ...normalImg },
    slot: file(relativePath: { eq: "extention/slot.PNG"}) { ...normalImg },
    memory_slot: file(relativePath: { eq: "extention/memory_slot.PNG"}) { ...normalImg },
    extension_slot: file(relativePath: { eq: "extention/extension_slot.PNG"}) { ...normalImg },
    extension_slot2: file(relativePath: { eq: "extention/extension_slot2.PNG"}) { ...normalImg },
    graphicboard_spec: file(relativePath: { eq: "extention/graphicboard_spec.PNG"}) { ...normalImg },
    rokupin: file(relativePath: { eq: "extention/rokupin.PNG"}) { ...normalImg },
    mount: file(relativePath: { eq: "extention/mount.PNG"}) { ...normalImg },
    M2Slot: file(relativePath: { eq: "extention/M2Slot.PNG"}) { ...normalImg },
    komono: file(relativePath: { eq: "extention/komono.PNG"}) { ...normalImg },
    fiveinchbay: file(relativePath: { eq: "extention/fiveinchbay.PNG"}) { ...normalImg },
    cpufan: file(relativePath: { eq: "extention/cpufan.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`パソコンの拡張性とは？パソコン購入時に考えるべき拡張性の勘所`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="extention" alt="拡張性とは何か？" mdxType="Image" />
    <p>{`パソコンを購入する時に選択するパーツ(モジュール)はCPU、メモリ、ハードディスク、グラフィックボード等多岐に渡り、それぞれが幅広い価格帯で選択肢を持っている。また、BDプレーヤーやTVの再生など、機能をいくつか選択する必要もある。`}</p>
    <p>{`ところが、購入時に明確にパソコンで行いたいことが分かっている場合は稀であり、だからといって最初から高性能かつ多機能なパソコンを選択するとコストが嵩む事になる。`}</p>
    <p>{`そこで`}<strong parentName="p">{`最初は最低限必要と考えられるパーツのみを選定し、後に必要となった段階で新規にパーツを購入して能力や機能の拡張を行うと経済的`}</strong>{`である。`}</p>
    <p><strong parentName="p">{`拡張性とはパソコンを購入後に機能の付加や能力の増強を行うための余地や容易さのこと`}</strong>{`である。`}</p>
    <p>{`拡張は基本的にプラスドライバーが一本あれば十分である。パソコンケースを分解し、コネクターやスロットにパーツを抜き差ししてケースを閉じ、再起動すると自動で新しいパーツをパソコンが認識してくれている。`}</p>
    <p>{`ただしパーツにより拡張のしやすさはまちまちで、更に筐体(パソコンケース)により増設できるパーツにも制限が掛かる。ここではパソコン購入時における注意点を拡張性の観点から述べていきたいと思う。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "パソコンケースと拡張部位の基礎知識",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%B1%E3%83%BC%E3%82%B9%E3%81%A8%E6%8B%A1%E5%BC%B5%E9%83%A8%E4%BD%8D%E3%81%AE%E5%9F%BA%E7%A4%8E%E7%9F%A5%E8%AD%98",
        "aria-label": "パソコンケースと拡張部位の基礎知識 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンケースと拡張部位の基礎知識`}</h2>
    <p>{`拡張性を説明するにあたってパソコンケースの種類と、パーツの取り付け部分及びコネクター、スロットに関する名称を知っておく必要がある。ここで最低限を把握しておこう。`}</p>
    <h3 {...{
      "id": "パソコンケースの種類",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%B1%E3%83%BC%E3%82%B9%E3%81%AE%E7%A8%AE%E9%A1%9E",
        "aria-label": "パソコンケースの種類 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンケースの種類`}</h3>
    <p>{`まずはパソコンケースである。当然大きいほど拡張性が高いわけだが、大きさと名称が一致しないと話が分からなくなるので下図にて定義しておく。`}</p>
    <Image {...props} name="pcbody" alt="デスクトップパソコンのケースサイズの比較" mdxType="Image" />
    <p>{`サイズはおおよそであるが、大きさにより`}<strong parentName="p">{`タワー型(ミドルタワー）、ミニタワー、スリム型`}</strong>{`が一般的である。このサイズを想定して記事を書いていく。`}</p>
    <p>{`その他、さらに小型のデスクトップパソコンや、モニターとの一体型のパソコンがあるが、これらは拡張性はほぼ皆無でかろうじてメモリを１枚追加できる程度である。ノートパソコンについても同様で、拡張性はほぼ期待できない。`}</p>
    <h3 {...{
      "id": "パーツの取り付け部分の名称",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%83%BC%E3%83%84%E3%81%AE%E5%8F%96%E3%82%8A%E4%BB%98%E3%81%91%E9%83%A8%E5%88%86%E3%81%AE%E5%90%8D%E7%A7%B0",
        "aria-label": "パーツの取り付け部分の名称 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パーツの取り付け部分の名称`}</h3>
    <p>{`次にパーツの取り付け部分についてだが、５インチベイ、3.5インチベイ、3.5インチシャドウベイ、拡張スロットを抑えておこう。シャドウベイは、パソコンケースの外からは見えないためにそう呼ばれている。`}</p>
    <Image {...props} name="slot" alt="ベイと拡張スロット" mdxType="Image" />
    <p>{`写真中、５インチベイの一つはDVDスーパーマルチドライブに使われており、もう一つは空。3.5インチベイは両方とも使われていない。シャドウベイは見えにくいが、一つは1TBのハードディスク、もう一つは480GBのSSDが入っている。また、拡張スロットは４つあり、一番上はDVI-D端子とHDMIケーブルが見えるが、グラフィックボードが挿入されている。`}</p>
    <h2 {...{
      "id": "後から増設可能なパーツ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BE%8C%E3%81%8B%E3%82%89%E5%A2%97%E8%A8%AD%E5%8F%AF%E8%83%BD%E3%81%AA%E3%83%91%E3%83%BC%E3%83%84",
        "aria-label": "後から増設可能なパーツ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`後から増設可能なパーツ`}</h2>
    <p>{`まずは後から増設を比較的容易に行えるパーツについて説明する。以下で説明するパーツはパソコンを購入する時にあまり神経質になって考える必要はなく、必要になり次第増強すればよいものである。ただし拡張性能によっては増設が不可能なこともあるため、各パーツごとに注意点を述べる。`}</p>
    <h3 {...{
      "id": "メモリ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA",
        "aria-label": "メモリ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリ`}</h3>
    <p>{`メモリーは最も拡張が容易なパーツであり、マザーボードと呼ばれる基盤に付属する`}<strong parentName="p">{`メモリースロットに、メモリーモジュールを挿すだけ`}</strong>{`で増設が完了する。`}</p>
    <Image {...props} name="memory_slot" alt="メモリースロット" mdxType="Image" />
    <p>{`メモリースロットの数はタワー型ならば４つ、ミニタワーならば４つか２つ、スリムタワーやノートPCだと２つが標準的である。よって例えばスリムタワーで4GBのメモリーを２枚すでに搭載している場合に16GBにするには、両方のメモリを破棄して8GBのメモリを２つ買う必要がある。`}</p>
    <p>{`メモリースロットが２つの場合に、`}<strong parentName="p">{`後に16GBへ増設するつもりがあるならば、購入時は4GB２枚ではなく8GBを１枚にしておくと良い`}</strong>{`。ただしこの場合、デュアルチャネルと呼ぶメモリーモジュール２枚１組でパソコンを高速化する技術が使用できないという問題点もある。`}</p>
    <p>{`デュアルチャネルは特にオンボードのグラフィック性能に効果がある。すなわちグラフィックボードを積んでいない場合に特に効果を発揮するため、グラフィックボードを積んでいれば4GB２枚と8GB１枚はそれほど速度に差は出ない。グラフィック性能を活かしたゲームなどをしない限りは特に影響がないと言えるだろう。`}</p>
    <p>{`また`}<strong parentName="p">{`モバイルノートや、タブレットに分解できるようなノートPCはメモリーを増設できない場合も多い`}</strong>{`。省スペース内に部品を押し込めるために、スロットがなくはんだ付けされていたり、構造上ユーザからは取り外せない場所にあったりするためである。この場合は購入時に余裕を持ったメモリーを確保しておこう。`}</p>
    <h3 {...{
      "id": "グラフィックボード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89",
        "aria-label": "グラフィックボード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グラフィックボード`}</h3>
    <p>{`最初はゲームをしないつもりでいたけれど、高画質な3DゲームやVRをやりたくなったという場合にはグラフィックボードを増設する必要がある。`}</p>
    <p>{`グラフィックボードは拡張スロットから増設することになる。拡張スロットは、PCI Expressという規格に対応したパーツを挿し込むことで使用することができる。`}</p>
    <Image {...props} name="extension_slot" alt="拡張スロットとPCI Expressスロット" mdxType="Image" />
    <p>{`上の写真でPCI Expressスロットの長さが異なる事に気づくと思うが、グラフィックボードはPCI Express x16と呼ばれる長いスロットを使用するのが一般的である。`}</p>
    <p>{`写真下の２つはPCI Express x1と呼ばれ、伝送速度はPCI Express x16と比べると16分の1のスロットである。こちらはUSB端子の拡張や後述するサウンドカードの増設など様々な用途で使用される。`}</p>
    <p>{`その他レーンの長さによってPCI Express x8、PCI Express x4も存在する。物理的にPCI Express x1に対応した端子をPCI Express x4、x8、x16の端子に挿すことができ、ソフトウェア的にも認識し、大が小を兼ねている。`}</p>
    <p>{`また、拡張スロット数は４なのに、PCI Expressの数は3と数が合っていないのではと思うかもしれない。これはそういうもので、マザーボードの種類によってもケースの種類によってもスロット数はまちまちである。しかしグラフィックボードを搭載するために標準的なPCI Express x16が１枚もないことはまずない。`}</p>
    <Image {...props} name="extension_slot2" alt="グラフィックボードも挿すだけで認識してくれる" mdxType="Image" />
    <p>{`さて、グラフィックボード増設の注意点として、`}<strong parentName="p">{`３万円以上するようなミドルエンドのグラフィックボードとなるとサイズが大きくなり、拡張スロットも２スロット分を消費する`}</strong>{`。
まず`}<strong parentName="p">{`スリム型では増設は不可能`}</strong>{`である。ミニタワーだと大抵可能だが思われるがパーツの組み合わせ次第では不可能なこともありそうだ。`}</p>
    <p>{`また`}<strong parentName="p">{`ミニタワークラスのPCだと電源パワーが低い場合もあるため、グラフィックボードメーカーの製品ページに行き、推奨電力より下にはならないように`}</strong>{`しよう。`}<a parentName="p" {...{
        "href": "https://www.nvidia.co.jp/object/geforce_family_jp.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Geforceのグラフィックボード`}</a>{`の製品詳細ページには最小限必要な電力が記述されているので参考にすべきである。`}</p>
    <Image {...props} name="graphicboard_spec" alt="グラフィックカードのスペック" mdxType="Image" />	
    <p>{`これはGeforce GTX 1060というグラフィックボードの仕様であるが、400Wは電源ユニットと呼ばれる、パソコン全体に電力を供給するパーツの推奨定格出力の基準を表す。定格出力は安定して出力できる電力量のことで電源ユニットの仕様に表記されている。`}</p>
    <p>{`グラフィックボード自体の消費電力の目安は120Wで、400Wとはかなり差があるが、CPUやマザーボードが消費する電力を合わせ、更に十分な余裕を確保するとそれぐらいの電力量は欲しいということである。`}</p>
    <p>{`また、`}<strong parentName="p">{`ミドルクラス以上のグラフィックボードではPCI Expressのみからの電力供給では不十分であるため補助電源コネクタが必要`}</strong>{`となる。スペック表に6-Pinとあるが、これはPCI Expressの6ピンと呼ばれる規格が補助電源コネクタとして必要で、これは電源ユニットから生えている。形は下図のようなものである。`}</p>
    <Image {...props} name="rokupin" alt="電源ユニットの6ピン" mdxType="Image" />
    <p>{`６ピンの他に８ピンの場合もある。８ピンがない場合は電源ユニットの定格出力には注意しつつ、`}<a parentName="p" {...{
        "href": "https://amzn.to/2O49fgj",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`6ピンから８ピンへ変換するコネクタ`}</a>{`が販売されているためそれを購入すればよい。`}</p>
    <p>{`ともあれ電源ユニットがグラフィックボードの求める定格出力を上回っていれば何かしら変換してグラフィックボードを作動させられるはずなので、パソコン購入時に神経質になる必要はない。`}</p>
    <h3 {...{
      "id": "HDDSSD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#HDDSSD",
        "aria-label": "HDDSSD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDD/SSD`}</h3>
    <p>{`HDDとSSDも増設しやすいパーツである。まずHDDの増設の方法としては3.5インチシャドウベイ、または3.5インチベイにHDDを入れてネジなどで固定し、SATA電源ケーブルとSATAケーブルをそれぞれHDDに挿し込むだけで良い。SATA電源ケーブルは電源ユニットから伸びており、SATAケーブルのもう片方の端子はマザーボードに挿し込む。`}</p>
    <p>{`シャドウベイを使うのが一般的であるが、呼び名はどうであれベイは単なるスペースであるため、ある程度固定できさえすればどこにおいても問題がない。`}</p>
    <p>{`注意として、`}<a parentName="p" {...{
        "href": "https://amzn.to/2Mdpq9x",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`SATAケーブル`}</a>{`は大抵HDDを購入しても付属してこないため自分で購入するしかない。SATA電源ケーブルももし足りない場合は`}<a href="https://amzn.to/2Qf30YF">{`分岐できるケーブル`}</a>{`を購入し端子の数を増やそう。`}</p>
    <p>{`次に2.5インチのSSD/HDDを増設する時であるが、3.5インチシャドウベイに入れただけでは小さすぎて固定されないため`}<a parentName="p" {...{
        "href": "https://amzn.to/2MdtYwo",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`2.5インチから3.5インチへ変換するマウンター`}</a>{`を使う。`}</p>
    <Image {...props} name="mount" alt="2.5インチから3.5インチへ変換するマウンター" mdxType="Image" />
    <p>{`また高速なSSDのスロットとして、2.5インチではなくM.2スロットと呼ばれるものもある。こちらは挿してネジで固定するだけのため簡単である。固定ねじがない場合には`}<a parentName="p" {...{
        "href": "https://amzn.to/2OhR3Ad",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`M．2 SSD固定用ミリネジ`}</a>{`などを購入する必要はある。`}</p>
    <Image {...props} name="M2Slot" alt="M2SSD用のスロット" mdxType="Image" />
    <p>{`さて、次に注意すべき点を述べる。まず`}<strong parentName="p">{`2.5インチ、3.5インチのストレージ(HDD/SSD)の増設はタワー型かミニタワーのみ可能`}</strong>{`である。ノートパソコンやスリム型は増設のためのスペースが基本的には用意されていない。この場合は外付けのストレージに頼ることになるだろう。M.2 SSDならばサイズも小さいためスリム型での増設は可能である。`}</p>
    <p>{`次に重要な点として、`}<strong parentName="p">{`起動OSをHDDからSSDに変えることはかなり難易度が高い`}</strong>{`ことが挙げられる。すなわち、最初HDDを使っていたがアプリの起動速度などが気になりだし、SSDに交換しようとしても難しい。`}</p>
    <p>{`ストレージの中身をそのまま他のストレージに移すことをクローンと呼ぶが、HDDからHDDへのクローンはできても、`}<strong parentName="p">{`HDDからSSDへのクローンは互換性の問題かうまくいかない`}</strong>{`ケースが多い。`}</p>
    <p>{`必要なファイルのバックアップを取り、Windowsの回復ドライブを作成し、回復ドライブからSSDへWindowsをインストールする事もできる。しかし手間もかかるためOSを起動するストレージをHDDからSSDへ移すことは可能だができる限り避けたい。`}</p>
    <h3 {...{
      "id": "TV-Tunerサウンドカード無線LANカード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#TV-Tuner%E3%82%B5%E3%82%A6%E3%83%B3%E3%83%89%E3%82%AB%E3%83%BC%E3%83%89%E7%84%A1%E7%B7%9ALAN%E3%82%AB%E3%83%BC%E3%83%89",
        "aria-label": "TV Tunerサウンドカード無線LANカード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TV Tuner/サウンドカード/無線LANカード`}</h3>
    <p>{`パソコン経由でテレビを見たり、録画をしたい場合はTV Tunerを増設できる。増設にはPCI Express x1を使うものが多い。`}</p>
    <p>{`またブツブツというノイズのある音を除去したい場合や、5.1chなどのサラウンド環境を作りたいときにはサウンドカードを増設する。サウンドカードもPCI Express x1を使うものが一般的である。`}</p>
    <p>{`有線の方が速いが、配線の都合上無線でどうしてもつながなければならない場合には無線LANを増設できるが、これもPCI Express x1を用いることが多い。`}</p>
    <p>{`PCI Expressのスロット数はミニタワーで２スロット占有のグラフィックボードをつけてしまうと、せいぜい残り１つになってしまうので注意したいポイントである。`}</p>
    <h3 {...{
      "id": "その他",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96",
        "aria-label": "その他 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他`}</h3>
    <p>{`５インチベイはブルーレイディスクプレイヤーの増設に使えるほか、使い道が無ければその大きさを利用して`}<a href="https://amzn.to/2x0yt84">{`小物入れ`}</a>{`に使ってもよい。SDカードリーダーや追加のUSB挿し込み口を追加するのも一般的である。`}</p>
    <Image {...props} name="komono" alt="小物入れは意外と便利である。" mdxType="Image" />
    <p>{`ただし５インチベイを使用するためにはパソコンケースのサイド及びフロントを全て外し、中に金属板がある場合はそれを取る作業が発生し、それなりに面倒である。`}</p>
    <p>{`ともあれ難しい作業ではないのでやる気さえあれば問題とはならないはずである。`}</p>
    <Image {...props} name="fiveinchbay" alt="5インチベイの金属板を外す" mdxType="Image" />	
    <h2 {...{
      "id": "交換になり、難易度も高いパーツ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BA%A4%E6%8F%9B%E3%81%AB%E3%81%AA%E3%82%8A%E3%80%81%E9%9B%A3%E6%98%93%E5%BA%A6%E3%82%82%E9%AB%98%E3%81%84%E3%83%91%E3%83%BC%E3%83%84",
        "aria-label": "交換になり、難易度も高いパーツ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`交換になり、難易度も高いパーツ`}</h2>
    <p>{`今までは主に拡張することを話してきたが、パーツを交換するという選択肢もある。ただし交換は既存のパーツを廃棄しなければならずコストも高い。`}</p>
    <h3 {...{
      "id": "CPU",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU",
        "aria-label": "CPU permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPU`}</h3>
    <p>{`基本的には購入したものをそのまま使うの良い。CPUの交換は、まずCPUクーラーをどかしてCPUを取り除く必要があるが、この取り外し、取り付け作業が非常にデリケートなものでかつ作業業が多い。慣れていないと大変である。`}</p>
    <p>{`また新しいCPUが登場してそちらに変更しようと思ってもマザーボードとの互換性の問題で取り付けができないケースも多い。例えば第８世代のIntel CPUは一年前に発売された第７世代に対応したマザーボードでは取り付けられない問題がある。`}</p>
    <p>{`交換したくてもできない場合も多いため`}<strong parentName="p">{`CPUはスペックに余裕をもって選択することをお勧めする`}</strong>{`。`}</p>
    <h3 {...{
      "id": "CPUクーラー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%82%AF%E3%83%BC%E3%83%A9%E3%83%BC",
        "aria-label": "CPUクーラー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUクーラー`}</h3>
    <p>{`CPUの負荷率が高い時にパソコンの音が大きくなる原因の一つは、CPUクーラーである。特に高負荷がかかると回転速度が速くなり音も大きくなる。これを抑えるためにはCPUクーラーを変更するのが良い。ところがこの作業は結構面倒である。`}</p>
    <Image {...props} name="cpufan" alt="cpuクーラーの組み立て" mdxType="Image" />	
    <p>{`画像のように部品点数が多く、CPUパーツとの接触部に熱伝導率を高めるためにグリスを塗るなど作業は細かく、自作パソコンを作ることが趣味という人以外にはあまりおすすめできない。`}<strong parentName="p">{`音が気にかもしれないと思ったら最初から静音オプションやCPUクーラーの選択オプションがあればつけておくべき`}</strong>{`である。`}</p>
    <p>{`静音性について、音が気になるかは人によるためなんとも言えないが一つ例をあげると、筆者が購入した静音オプションをつけないミニタワーパソコンを計測した人がいて33db（デシベル)程度であった。`}</p>
    <p>{`これは静かな扇風機程度の音で、窓を開けていると音はほとんど聞こえない。また、エアコンの音が46-50db程度なのでそれよりはかなり静かである。たまに少し気になるかならないか程度の微妙な感じではある。`}</p>
    <h3 {...{
      "id": "電源ユニット",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9B%BB%E6%BA%90%E3%83%A6%E3%83%8B%E3%83%83%E3%83%88",
        "aria-label": "電源ユニット permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電源ユニット`}</h3>
    <p>{`電源ユニットも交換が難しいパーツである。グラフィックボードの項目で説明したように、後に拡張したときは推奨電力を下回ることがないように気を付けよう。`}</p>
    <p>{`後の拡張性を考えたおよその目安としては`}<strong parentName="p">{`スリムタワーでは300W、ミニタワーでは400W、タワー型では500Wが最低限欲しい基準`}</strong>{`である。`}</p>
    <p>{`また、省エネの観点でも電源ユニットの選択が関わってくる。電源ユニットの性能を表す指標として電力の変換効率がある。`}</p>
    <p><strong parentName="p">{`80 PLUS`}</strong>{`という変換効率の高さを米国の企業が認証する制度があり、`}<strong parentName="p">{`変換効率によってGOLD、ブロンズといった称号がグレード別に与えられる`}</strong>{`。80 PLUSのグレードと規定された変換効率は下図のようになる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
	<thead>
		<tr>
			<th></th>
			<th>負荷20%</th>
			<th>負荷50%</th>
			<th>負荷80%</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th style={{
            backgroundColor: 'white',
            color: 'black'
          }}>STANDARD</th>
			<td>80%</td>
			<td>80%</td>
			<td>80%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#cd7f32',
            color: 'black'
          }}>BRONZE</th>
			<td>82%</td>
			<td>85%</td>
			<td>82%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: 'silver',
            color: 'black'
          }}>SILVER</th>
			<td>85%</td>
			<td>88%</td>
			<td>85%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: 'gold',
            color: 'black'
          }}>GOLD</th>
			<td>87%</td>
			<td>90%</td>
			<td>87%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#E5E4E2',
            color: 'black'
          }}>PLATINUM</th>
			<td>90%</td>
			<td>92%</td>
			<td>89%</td>
		</tr>
		<tr>
			<th style={{
            backgroundColor: '#DDD6E1',
            color: 'black'
          }}>TITANIUM</th>
			<td>92%</td>
			<td>94%</td>
			<td>90%</td>
		</tr>
	</tbody>
    </BasicTable>
    <p>{`負荷は電源に掛かる負荷であり、負荷が高くも低くもない50%の時に最も変換効率が高くなる。`}</p>
    <p><strong parentName="p">{`ほとんど電力を使用しないのに電力供給能力が高い電源ユニットを使うことは不要であるどころか、電気代も高くつく`}</strong>{`のである。`}</p>
    <p>{`また変換できなかったエネルギーは熱に変わる。そのためケース内の温度が高くなり、その結果ファンの回転数が上がり、`}<strong parentName="p">{`静音性やパフォーマンスにも影響が出てしまう可能性がある`}</strong>{`ため注意が必要である。`}</p>
    <p>{`部屋の隅など排熱性が悪い場所にパソコンを置き、パソコン内も余分なスペースがないためエアフローが悪く、更に大型のグラフィックボードを搭載するなどパソコンが熱くなりそうな場合は極力変換効率が高い電源ユニット尾を搭載しておくことがお勧めである。`}</p>
    <p>{`なお500Wの電源ユニットで変換効率が80%だと400Wの電力しか供給できないのでは？と思うかもしれないが間違いで、正解は500Wの電力が供給されるが625W分の電力がコンセントから必要である。`}</p>
    <h2 {...{
      "id": "拡張はどれぐらいできる？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%8B%A1%E5%BC%B5%E3%81%AF%E3%81%A9%E3%82%8C%E3%81%90%E3%82%89%E3%81%84%E3%81%A7%E3%81%8D%E3%82%8B%EF%BC%9F",
        "aria-label": "拡張はどれぐらいできる？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`拡張はどれぐらいできる？`}</h2>
    <p>{`最後にタワー型、ミニタワー、スリム型でそれぞれどの程度拡張性に差があるかをまとめていく。`}</p>
    <h3 {...{
      "id": "拡張性は物理サイズ、マザーボード、電力量に依存",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%8B%A1%E5%BC%B5%E6%80%A7%E3%81%AF%E7%89%A9%E7%90%86%E3%82%B5%E3%82%A4%E3%82%BA%E3%80%81%E3%83%9E%E3%82%B6%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%80%81%E9%9B%BB%E5%8A%9B%E9%87%8F%E3%81%AB%E4%BE%9D%E5%AD%98",
        "aria-label": "拡張性は物理サイズ、マザーボード、電力量に依存 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`拡張性は物理サイズ、マザーボード、電力量に依存`}</h3>
    <p>{`まず基本的な話をすると、拡張性はパソコンケースによる物理的なサイズ、マザーボードの仕様（各スロットの数等)、電源ユニットの供給電力の主に３つに依存する。`}</p>
    <p>{`タワー型の拡張性が高いと言っている理由は、パソコンケースの物理サイズが大きい他、使用者が後にパーツを拡張することを見越して、パソコンメーカーがスロットの多いマザーボードと十分な電力を供給する電源ユニットを準備するからである。`}</p>
    <p>{`パソコンケースは言わばただの箱なのでそれだけでは拡張性は高くはならない。`}</p>
    <p>{`スロット数の多いマザーボードといったが、`}<strong parentName="p">{`マザーボードには形状(フォームファクタ)の大きい順にATX、MicroATX、Mini-ITXという３つの規格が存在し、PCI Expressやメモリーのスロット数もこの順で多い。`}</strong></p>
    <p>{`タワー型はATX、ミニタワー、スリムタワーはMicroATXを一般的に搭載するためタワー型の拡張性が最も高くなるのである。`}</p>
    <p>{`タワー型にMicroATXのマザーボードと300Wの電源ユニットを採用する構成も可能であるが、知る限り売られてはいないので自作でのみということになる。`}</p>
    <h3 {...{
      "id": "ケース別拡張性の目安",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B1%E3%83%BC%E3%82%B9%E5%88%A5%E6%8B%A1%E5%BC%B5%E6%80%A7%E3%81%AE%E7%9B%AE%E5%AE%89",
        "aria-label": "ケース別拡張性の目安 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ケース別拡張性の目安`}</h3>
    <p>{`話を戻す。各ケース、十分な電力供給を確保(スリム型300W、ミニタワー400W、タワー型500W)し標準的なマザーボードを搭載した時の目安、言い換えると、自作をせずに店で標準的なパソコンを購入した時は保守的に見積もり下の表ぐらいを目安に考えよう。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
	<thead>
		<tr>
			<th></th>
			<th>ノートPC</th>
			<th>スリム型</th>
			<th>ミニタワー</th>
			<th>ミドルタワー</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<th>メモリ</th>
			<td>×か計２枚</td>
			<td>計２枚</td>
			<td>計２枚か４枚</td>
			<td>計４枚</td>
		</tr>
		<tr>
			<th>グラフィックボード</th>
			<td>×</td>
			<td>ローエンドのみ</td>
			<td>ミドルエンド<br />(約4万円以下)まで</td>
			<td>〇</td>
		</tr>
		<tr>
			<th>2.5/3.5インチHDD/SDD</th>
			<td>×</td>
			<td>×</td>
			<td>３台程度</td>
			<td>４－６</td>
		</tr>
		<tr>
			<th>M.2 SSD</th>
			<td>△</td>
			<td>〇</td>
			<td>〇</td>
			<td>〇</td>
		</tr>
		<tr>
			<th>拡張スロット</th>
			<td>０</td>
			<td>２</td>
			<td>３</td>
			<td>４－６</td>
		</tr>
		<tr>
			<th>５インチベイ</th>
			<td>０</td>
			<td>１</td>
			<td>１－２</td>
			<td>１－３</td>
		</tr>
		<tr>
			<th>3.5インチベイ</th>
			<td>０</td>
			<td>０</td>
			<td>０－２</td>
			<td>０－２</td>
		</tr>
		<tr>
			<th>3.5インチシャドウベイ</th>
			<td>０</td>
			<td>１</td>
			<td>２程度</td>
			<td>３－５</td>
		</tr>
	</tbody>
    </BasicTable>
    <br />
備考：<br />
    <ul>
      <li>モバイルノートはメモリが増設できないことも多い</li>
      <li>グラフィックボードは拡張スロットを１か２消費</li>
      <li>2.5/3.5インチHDD/SSDは3.5インチベイあるいは3.5インチシャドウベイを数量分消化</li>
      <li>3.5インチの代わりに2.5インチのベイがあるときも多い</li>
      <li>M.2 SSDはマザーボードによっては無い。複数あるものもある</li>	
      <li>5インチベイの一つはDVDあるいはBDドライブに使われる</li>
    </ul>
    <p>{`目安と言ってみたものの、やはりケースによってばらつきも大きいので正確に把握したい場合は製品の仕様をしっかりと確認しよう。拡張スロットの数やオープンベイの空きなどが表記されているはずである。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      